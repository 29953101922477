<template>
  <el-dialog :visible="dialogVisible" bottom="30px" width="1200px" @closed="onClose" @close="onClose">
    <div class="rebateReportDialog">
      <div class="title">
        <p>{{ $t('ibAccounts.tradingList') }}</p>
      </div>
      <div class="table_content">
        <div class="title">
          <p>
            <span>{{ $t('ibAccounts.OpeningList') }}</span>
          </p>
        </div>
        <div class="mt-3 mb-3">
          <el-table ref="openTradingList" :data="openTradingList | tableDataPageFilter(pageNo, size)">
            <el-table-column prop="ticket" :label="$t('ibAccounts.tradingListColumn.ticket')" min-width="100">
            </el-table-column>
            <el-table-column prop="openTime" :label="$t('ibAccounts.tradingListColumn.openTime')" min-width="120">
            </el-table-column>
            <el-table-column prop="type" :label="$t('ibAccounts.tradingListColumn.type')" min-width="100">
              <template slot-scope="traderTableScope">
                {{ traderTableScope.row.type === 0 ? $t('buy') : $t('sell') }}
              </template>
            </el-table-column>
            <el-table-column
              prop="volume"
              :label="$t('ibAccounts.tradingListColumn.volume')"
              min-width="100"
            ></el-table-column>
            <el-table-column
              prop="item"
              :label="$t('ibAccounts.tradingListColumn.item')"
              min-width="100"
            ></el-table-column>
            <el-table-column prop="openPrice" :label="$t('ibAccounts.tradingListColumn.openPrice')" min-width="120">
              <template slot-scope="traderTableScope">
                {{traderTableScope.row.openPrice}}
              </template>
            </el-table-column>
            <el-table-column prop="closePrice" :label="$t('ibAccounts.tradingListColumn.closePrice')" min-width="120">
              <template slot-scope="traderTableScope">
                {{traderTableScope.row.closePrice}}
              </template>
            </el-table-column>
            <el-table-column prop="commission" :label="$t('ibAccounts.tradingListColumn.commission')" min-width="120">
              <template slot-scope="traderTableScope">
                {{ Number(traderTableScope.row.commission).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="profit" :label="$t('ibAccounts.tradingListColumn.profit')" min-width="120">
              <template slot-scope="traderTableScope">
                {{ Number(traderTableScope.row.profit).toFixed(2) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          :page-size="10"
          layout="total, sizes, prev, pager, next"
          :page-sizes="[10, 25, 50, 100]"
          :total="openTotal"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="pageNo"
          data-testid="pagination"
        ></el-pagination>
      </div>
      <div class="calendar_content">
        <DateRangePicker v-bind:start-date.sync="startTime" v-bind:end-date.sync="endTime"></DateRangePicker>
        <div class="right_box">
          <el-button plain @click="updateCloseList" data-testid="UPDATE">
            {{ $t('common.keys.UPDATE') }}
          </el-button>
        </div>
      </div>
      <div class="table_content">
        <div class="title">
          <p>
            <span>{{ $t('ibAccounts.closeList') }}</span>
          </p>
        </div>
        <div class="mt-3 mb-3">
          <el-table ref="closeTradingList" :data="closeTradingList | tableDataPageFilter(pageNo, size)">
            <el-table-column prop="ticket" :label="$t('ibAccounts.tradingListColumn.ticket')" min-width="100">
            </el-table-column>
            <el-table-column prop="openTime" :label="$t('ibAccounts.tradingListColumn.openTime')" min-width="120">
            </el-table-column>
            <el-table-column prop="type" :label="$t('ibAccounts.tradingListColumn.type')" min-width="100">
              <template slot-scope="traderTableScope">
                {{ traderTableScope.row.type === 0 ? $t('buy') : $t('sell') }}
              </template>
            </el-table-column>
            <el-table-column
              prop="volume"
              :label="$t('ibAccounts.tradingListColumn.volume')"
              min-width="100"
            ></el-table-column>
            <el-table-column
              prop="item"
              :label="$t('ibAccounts.tradingListColumn.item')"
              min-width="100"
            ></el-table-column>
            <el-table-column prop="openPrice" :label="$t('ibAccounts.tradingListColumn.openPrice')" min-width="120">
              <template slot-scope="traderTableScope">
                {{traderTableScope.row.openPrice}}
              </template>
            </el-table-column>
            <el-table-column prop="closePrice" :label="$t('ibAccounts.tradingListColumn.closePrice')" min-width="120">
              <template slot-scope="traderTableScope">
                {{ traderTableScope.row.closePrice }}
              </template>
            </el-table-column>
            <el-table-column prop="commission" :label="$t('ibAccounts.tradingListColumn.commission')" min-width="120">
              <template slot-scope="traderTableScope">
                {{ Number(traderTableScope.row.commission).toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="profit" :label="$t('ibAccounts.tradingListColumn.profit')" min-width="120">
              <template slot-scope="traderTableScope">
                {{ Number(traderTableScope.row.profit).toFixed(2) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          :page-size="10"
          layout="total, sizes, prev, pager, next"
          :page-sizes="[10, 25, 50, 100]"
          :total="closeTotal"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="pageNo"
          data-testid="pagination"
        ></el-pagination>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { apiIbAccounts_AccNmber_Close } from '@/resource';
import DateRangePicker from '@/components/DateRangePicker';
export default {
  components: { DateRangePicker },
  props: {
    dialogVisible: Boolean,
    accountNumber: Number,
    openTradingList: Array
  },
  data() {
    return {
      startTime: '',
      endTime: '',
      closeTradingList: [],
      closeTradingListUrl: 'close_trade_list',
      closeTotal: 0,
      openTotal: 0,
      size: 10,
      // 分页数据
      pageNo: 1,
      pageCount: 6
    };
  },
  watch: {
    closeTradingList(val) {
      this.closeTotal = val.length;
    },
    openTradingList(val) {
      this.openTotal = val.length;
    }
  },
  filters: {
    tableDataPageFilter(tableData, pageNo, size) {
      if (tableData == undefined) {
        return;
      }
      let start = (pageNo - 1) * size;
      let end = pageNo * size;
      return tableData.slice(start, end);
    }
  },
  methods: {
    async updateCloseList() {
      let resp = await apiIbAccounts_AccNmber_Close(this.closeTradingListUrl, {
        account: this.accountNumber,
        startDateStr: this.startTime.split(' ')[0],
        endDateStr: this.endTime.split(' ')[0]
      });
      // this.closeTradingList = resp.data.data;
      this.closeTradingList = resp.data.data;
      this.closeTotal = resp.data.data.length;
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNo = val;
    },
    handleSizeChange(size) {
      this.size = size;
    },
    onClose() {
      this.$emit('update:dialogVisible', false);
      this.closeTradingList = [];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/rebateReport/dialog.scss';
@import '@/assets/css/pages/home/index.scss';
@import '@/assets/css/components/defaultDatePickerLayout.scss';

/deep/ .el-input {
  .el-input__inner {
    color: $white !important;
  }
}

/deep/ .el-table__cell {
  height: 3.5em !important;
}

/deep/ .el-pagination__total {
  color: $white !important;
}

/deep/ .el-pagination button:disabled {
  color: $white !important;
}
/deep/ .el-table__body-wrapper {
  background-color: #00323c !important;
}

.calendar_content {
  width: 100%;
  margin-top: 1.25rem;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 65% 12%;
  grid-template-rows: auto;
  grid-template-areas: 'calendar right_box';
  column-gap: 3%;
  margin-bottom: 30px;

  .calendar {
    grid-area: calendar;
  }

  .right_box {
    grid-area: right_box;
  }
}

p {
  font-size: 17px;
  color: $white;
  display: inline-block;
}
</style>
